import React, { useEffect, Fragment } from "react"
import CTA from "../general/cta"
import { scrollToElement } from "../../../utility/utils"
import { useState } from "react"
import BlogCategoriesSection from "./components/blog-categories-section"
import { BlogListCards } from "./components/blog-list-cards"
import { BlogListCardsSkeleton } from "./components/blog-list-cards-skeleton"
import { BlogListEmptyState } from "./components/blog-list-empty-state"
const Blog = (props) => {
  const [selectedFilter, setSelectedFilter] = useState()
  const [state, setState] = useState({
    categoriesFailed: false,
    blogsFailed: false,
    isCategoriesLoading: true,
    isBlogsLoading: true,
  })
  const [searchValue, setSearchValue] = useState("")
  const [filters, setFilters] = useState([])
  const [filteredBlogs, setFilteredBlogs] = useState([])
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })

    const fetchData = async () => {
      const categories = props?.categories ? props.categories : [];
      const sortedList = [
        ...categories?.filter(a => a.categoryKey === 'recent'),
        ...categories?.filter(a => a.categoryKey !== 'recent')
      ]
      setFilters(sortedList)
      setState({
        ...state,
        isCategoriesLoading: false,
        categoriesFailed: false,
        isBlogsLoading: true,
      })
      if (sortedList.length > 0) {
        const selectedGroup = sortedList?.[0]
        setSelectedFilter(selectedGroup)
        changeFilterGroup(selectedGroup, null)
      }
    }
    fetchData()
  }, [])


  async function changeFilterGroup(selectedCategoryFilter, searchInputValue) {
    const envBlogs = process.env.GATSBY_ENV !== "Test"? props?.blogs.filter(blog => !blog?.isTestBlog) : props.blogs
    if (selectedCategoryFilter) {
      const blogs = await envBlogs?.filter(blog => {
        const selectedBlogByGroup = blog.categories.find(category => category.categoryKey === selectedCategoryFilter.categoryKey)?.categoryKey
        const selectedBlogByName = searchInputValue ? blog.blogTitle.toLowerCase().includes(searchInputValue.toLowerCase()) : true
        if (selectedBlogByName && selectedBlogByGroup) {
          return blog
        }
      })
      setFilteredBlogs(blogs)
      setState({ ...state, isBlogsLoading: false, categoriesFailed: false })
    }
  }
  return (
    <Fragment>
    <div className="kuda-inner--heading">
      <div className="kuda-section--inner">
        <div className="kuda-section--100 text-center">
          <h1 className="text-xl text-xlbold color-primary title-bottom--spacing">
            Blog
          </h1>
          <div className={`kuda-section--100 flex justify-center`}>
            <div className="kuda-main--wrap flex flex-column align-center mt-0">
              <span className="card--heading text-semi-bold color-black text-center">
                Follow our train of thought, get fresh updates and dive into eye-opening money guides.
              </span>
            </div>
          </div>
        </div>

        {state.categoriesFailed ? (
          <BlogListEmptyState
            title={"Internal server error"}
            subTitle={"Sorry, an unexpected error occured. Please try again."}
          />
        ) : (
          <>
            <BlogCategoriesSection
              filters={filters}
              selectedFilter={selectedFilter}
              searchValue={searchValue}
              onFilterGroupChanged={value => {
                if (value) {
                  setState({ ...state, isBlogsLoading: true })
                  setSearchValue('')
                  setSelectedFilter(value)
                  changeFilterGroup(value, null)
                }
              }}
              onSearchValueChanged={() => {
                if (searchValue?.length > 0 && !state.isBlogsLoading) {
                  setState({ ...state, isBlogsLoading: true })
                  changeFilterGroup(selectedFilter, searchValue)
                }

              }}

              onSearchInputChanged={setSearchValue}
            />

            <div className="kuda-ignore--container">
              {state.isBlogsLoading ? (
                <BlogListCardsSkeleton blogNumber={2} />
              ) : filteredBlogs.length > 0 ? (
                <BlogListCards blogs={filteredBlogs} />
              ) : (
                <BlogListEmptyState />
              )}
            </div>
          </>
        )}
      </div>
    </div>
    <CTA />
  </Fragment>
  )
}

export default Blog
